import {useQuery} from "@apollo/client";
import {Box, Button, Center, Divider, Flex, Link, Spinner, Stack, Text} from "@chakra-ui/react";
import {NavLink as ReactRouterLink} from "react-router-dom";
import {gotoStripeBilling} from "../../api/goto-stripe-billing";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {CurrentUser} from "../../graphql/__generated__/CurrentUser";
import {BillingSummary} from "./billing-summary";

export const Billing = () => {
  const { data: currentUser, loading } = useQuery<CurrentUser>(CURRENT_USER_QUERY);
  if (loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  const currentlySubscribed = currentUser?.currentUser.account?.status === "active" || currentUser?.currentUser.account?.status === "trialing";
  return (
    <Stack spacing="5">
      <Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="medium">
            Your Subscription
          </Text>
          <Text color="muted" fontSize="sm">
            Current plan type and status
          </Text>
        </Box>
        <CurrentPlanStatus
          plan={currentUser?.currentUser.account?.plan}
          status={currentUser?.currentUser.account?.status}
          prettified_subscription_level={currentUser?.currentUser.account?.prettifiedSubscriptionLevel}
        />
      </Stack>
      <Divider />
      <Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="medium">
            Billing Summary
          </Text>
          <Text color="muted" fontSize="sm">
            Details of your upcoming charges, and renewal schedule
          </Text>
        </Box>
        <Box>
          <BillingSummary
            upcomingInvoiceAmount={currentUser?.currentUser.account?.upcomingInvoiceAmount}
            upcomingAmountOff={currentUser?.currentUser.account?.upcomingAmountOff}
            upcomingPercentOff={currentUser?.currentUser.account?.upcomingPercentOff}
            upcomingChargeDate={currentUser?.currentUser.account?.upcomingChargeDate}
            status={currentUser?.currentUser.account?.status}
            trialEnd={currentUser?.currentUser.account?.trialEnd}
            canceledAt={currentUser?.currentUser.account?.canceledAt}
            currentPeriodEnd={currentUser?.currentUser.account?.currentPeriodEnd}
            cancelAt={currentUser?.currentUser.account?.cancelAt}
          />
        </Box>
      </Stack>
      <Divider />
      <Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
        <Box>
          {!currentlySubscribed ? (
            <>
              <Text fontSize="lg" fontWeight="medium">
                Start your Kumo Paid Subscription
              </Text>
              <Text color="muted" fontSize="sm">
                Looks like you don't have an active subscription!
              </Text>
            </>
          ) : (
            <>
              <Text fontSize="lg" fontWeight="medium">
                Manage your Kumo Subscription
              </Text>
              <Text color="muted" fontSize="sm">
                Switch between Pro & Ultimate, view billing history & payment methods, or cancel your subscription
              </Text>
            </>
          )}
        </Box>
        {!currentlySubscribed ? (
          <Flex alignItems="center">
            <Button as={ReactRouterLink} to="/plan-selection" variant="primary" alignSelf="start">
              Upgrade to Pro or Ultimate
            </Button>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Button
              variant="primary"
              alignSelf="start"
              onClick={async () => {
                const resp = await gotoStripeBilling();
                window.location.replace(resp.redirect_url);
              }}>
              Manage Billing Profile
            </Button>
          </Flex>
        )}
      </Stack>
    </Stack>
  );
};

const CurrentPlanStatus = ({ plan, status, prettified_subscription_level }: { plan?: string | null; status?: string | null, prettified_subscription_level? : string | null }) => {
  if (status) {
    return (
      <Flex alignItems="center">
        <Stack spacing={0} alignItems={{ base: "flex-start", sm: "flex-end" }}>
          <Text fontWeight="medium" fontSize={"lg"}>
            {prettified_subscription_level}
          </Text>
          {plan === "Free" && (
            <Link
            textDecoration="underline"
            as={ReactRouterLink}
            to="/plan-selection"
            fontWeight="medium">
              Upgrade to Pro or Ultimate
            </Link>
            // <ProPlanCtaCheckout tracking="upgrade_pro_edit_settings_click">
            //   Upgrade to Pro
            // </ProPlanCtaCheckout>
          )}
        </Stack>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems="flex-end">
        <Stack spacing={0} alignItems={{ base: "flex-start", sm: "flex-end" }}>
          <Text fontWeight="medium">Incomplete</Text>
          <Text fontSize="sm" color="muted" textAlign={{ base: "left", sm: "right" }}>
            No Plan
          </Text>
          <Link
            textDecoration="underline"
            as={ReactRouterLink}
            to="/plan-selection"
            fontWeight="medium">
            Upgrade to Pro or Ultimate
          </Link>
          {/*<ProPlanCtaCheckout tracking="upgrade_pro_edit_settings_click">*/}
          {/*  Upgrade to Pro Plan for $30/mo*/}
          {/*</ProPlanCtaCheckout>*/}
        </Stack>
      </Flex>
    );
  }
};
